import { CookiePreferences } from '#lib/Cookies/cookie-preferences';
import { GoogleAnalyticsKey, MicrosoftClarityKey } from '#src/app-config.json';

/** Initialises cookies - changes to this should be made to Auth0 login (use the generated js file) */
function _initCookies(googleAnalyticsKey: string, microsoftClarityKey: string) {
  const cookieRequirements = [{
    name: 'googleAnalytics',
    filter: (preferences: CookiePreferences) => preferences.analyticalAndPerformance,
    action: () => {
      const script1 = document.createElement('script');
      script1.setAttribute('async', '');
      script1.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsKey}`);
      document.head.appendChild(script1);
      const script2 = document.createElement('script');
      script2.innerHTML = `
      window.dataLayer = window.dataLayer || []; 
      function gtag() { 
        dataLayer.push(arguments); 
      } 
      gtag('js', new Date()); 
      gtag('config', '${googleAnalyticsKey}');`;
      document.head.appendChild(script2);
    },
  }, {
    name: 'microsoftClarity',
    filter: (preferences: CookiePreferences) => preferences.analyticalAndPerformance &&
      preferences.marketingAndAdvertising,
    action: () => {
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.innerHTML = `
      (function(c,l,a,r,i,t,y){ 
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; 
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; 
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); 
      })(window, document, "clarity", "script", "${microsoftClarityKey}");`;
      document.head.appendChild(script);
    },
  }];
  const setCookies = (preferences: CookiePreferences) => {
    cookieRequirements.filter(x => x.filter(preferences))
      .forEach(cookieToSet => cookieToSet.action());
  };
  const onCookiePreferencesSet = () => {
    const preferences = JSON.parse(localStorage.getItem('nnrg-cookie-preferences'));
    setCookies(preferences);
    document.removeEventListener('cookie-preferences-set', onCookiePreferencesSet);
  };
  const preferences = JSON.parse(localStorage.getItem('nnrg-cookie-preferences'));
  if (preferences)
    setCookies(preferences);
  else
    document.addEventListener('cookie-preferences-set', onCookiePreferencesSet);
}

export function initCookies() {
  _initCookies(GoogleAnalyticsKey, MicrosoftClarityKey);
}
