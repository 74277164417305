import 'broadcastchannel-polyfill';

import type { Dialog } from '@material/mwc-dialog';
import type { Snackbar } from '@material/mwc-snackbar';
import type { TabBar } from '@material/mwc-tab-bar';
import type { SelectedDetail } from '@material/mwc-list/mwc-list-foundation';
import type { Select } from '@material/mwc-select';
import type { List } from '@material/mwc-list';

declare global {
  type RecursivePartial<T> = {
    [P in keyof T]?:
      T[P] extends (infer U)[] ? RecursivePartial<U>[] :
      // This is pretty low-level, I don't mind the lint error here.
      // eslint-disable-next-line @typescript-eslint/ban-types
      T[P] extends object ? RecursivePartial<T[P]> :
      T[P];
  };

  type Await<T> = T extends {
      then(onfulfilled?: (value: infer U) => unknown): unknown;
  } ? U : T;

  type SlotchangeEvent =
    Event & {
      target: HTMLSlotElement;
    };

  type DialogEvent =
    CustomEvent<{ action: string }> & {
      target: Dialog;
    }

  type SnackbarCloseEvent =
    CustomEvent<{ reason: string }> & {
      target: Snackbar;
    }

  type TabBarActivatedEvent =
    CustomEvent<{ index: number }> & {
      target: TabBar;
    };

  type SelectedEvent =
    CustomEvent<SelectedDetail> & {
      target: List|Select;
    }

  interface HTMLElementTagNameMap {
    'mwc-dialog': Dialog;
  }

  function requestIdleCallback(cb: (...args: any[]) => void): number

   interface FontFaceSet {
     readonly ready: Promise<FontFaceSet>;
     readonly status: FontFaceSetLoadStatus;
   }

   interface Document {
     fonts: FontFaceSet
  }
}

window.requestIdleCallback = window.requestIdleCallback ||
  function(cb) {
    return setTimeout(function() {
      const start = Date.now();
      cb({
        didTimeout: false,
        timeRemaining: function() {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1) as unknown as number;
  };

window.cancelIdleCallback = window.cancelIdleCallback ||
  function(id) {
    clearTimeout(id);
  };
